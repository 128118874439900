* {
  scroll-behavior: smooth !important;
}

.css-16sn2uu-MuiAutocomplete-listbox {
  height: 200px !important;
  max-height: 200px !important;
}

.refresh-btn{
  animation: spin 1.5s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}